import {createContext} from 'react';

export class I18n {
    constructor(private data: Record<string, string>, private preventDefault: boolean = false) {}
    public t(key: string, defaultText: string = ''): string  {
        if(this.data[key]) {
            return this.data[key];
        }
        if(this.preventDefault) {
            return '';
        }
        return defaultText;
    }
}

export async function loadI18nAsset(): Promise<Record<string, string> | null> {
    const fullLang = navigator.language || navigator.languages[0] || 'en';
    const lang = fullLang.split('-')[0];
    const resp = await fetch(`/assets/i18n/${lang}.json`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if(resp.status !== 200 && resp.status !== 304) {
        return null;
    }

    const data = await resp.json();
    return data;
}

const i18nContext = createContext(new I18n({}, true));
export default i18nContext;