export default class APIClient {
  private baseURL: string;
  private credential?: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  setCredential(credential?: string) {
    this.credential = credential;
    if (credential) {
    }
  }

  public async explainIngredients(file: File): Promise<ExplainIngredientResp> {
    const formData = new FormData();
    formData.append('image', file);
    const resp = await fetch(`${this.baseURL}/explain-ingredients/upload`, {
      method: 'POST',
      body: formData,
    });
    return resp.json();
  }

  public async compareIngredients(file: File, compareBase: { description: string, ingredients: string[] }): Promise<CompareIngredientsResp> {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('base', JSON.stringify(compareBase));
    const resp = await fetch(`${this.baseURL}/explain-ingredients/compare`, {
      method: 'POST',
      body: formData,
    });
    return resp.json();
  }
}

type ExplainIngredientResp = {
  error?: string;
  message?: string;
  product_description?: string,
  ingredients?: {
    name: string,
    description: string,
  }[],
};

type CompareIngredientsResp = {
  error?: string;
  message?: string;
  product_common?: string;
  product_difference?: string;
  common_ingredients?: {
    name: string,
    description: string,
  }[];
  product_a_unique_ingredients?: {
    name: string,
    description: string,
  }[];
  product_b_unique_ingredients?: {
    name: string,
    description: string,
  }[];
}