import { useCallback, useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ExplainIngredients from './explain-ingredients';
import APIClient from '../backend/api';
import I18nContext, { I18n, loadI18nAsset } from '../common/i18n-context';
import GoogleSSOContext, { GoogleSSO } from '../common/google-sso-context';

const islocal = window.location.host === 'localhost:3000';
const apiClient = new APIClient(!islocal ? `${window.location.protocol}//${window.location.host}/api` : 'http://localhost:4200/api');
const router = createBrowserRouter([
  {
    path: '/',
    element: <ExplainIngredients apiClient={apiClient} />
  }
]);

export default function RootApp() {
  const [i18n, setI18n] = useState<I18n>(new I18n({}, true));
  const [googleSSO, setGoogleSSO] = useState<GoogleSSO>(new GoogleSSO());

  const loadAndApplyI18nAsset = useCallback(async () => {
    const asset = await loadI18nAsset();
    if (asset) {
      setI18n(new I18n(asset));
    } else {
      setI18n(new I18n({})); // with default value
    }
  }, [setI18n]);

  useEffect(() => {
    loadAndApplyI18nAsset();
  }, [loadAndApplyI18nAsset]);

  useEffect(() => {
    document.title = i18n.t('app_title', 'Explain the ingredients with AI');
    const privacyPolicyHref = document.querySelector('#privacy-policy');
    if (privacyPolicyHref) {
      privacyPolicyHref.textContent = i18n.t('privacy_policy', 'Privacy Policy');
    }
  }, [i18n]);

  // setup google sso listener
  useEffect(() => {
    if (window.googleUser) {
      setGoogleSSO(new GoogleSSO(window.googleUser));
      apiClient.setCredential(window.googleUser.credential);
    }

    if (!window.googleSSOCallback) {
      window.googleSSOCallback = function (user) {
        setGoogleSSO(new GoogleSSO(user));
        apiClient.setCredential(user?.credential);
      }
    }
  }, []);

  return <I18nContext.Provider value={i18n}><GoogleSSOContext.Provider value={googleSSO}><RouterProvider router={router} /></GoogleSSOContext.Provider></I18nContext.Provider>;
}
