import React, { useContext, useEffect, useRef, useState } from 'react';
import APIClient from '../../backend/api';

import styles from './index.module.css';
import i18nContext, { I18n } from '../../common/i18n-context';

interface Props {
  apiClient: APIClient;
}

export default function ExplainIngredients(props: Props) {
  const i18n = useContext<I18n>(i18nContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [productDescription, setProductDescription] = useState<string>('');
  const [ingredients, setIngredients] = useState<{ name: string, description: string }[]>([]);

  const [productDifferences, setProductDifferences] = useState<string>('');
  const [productAIngredients, setProductAIngredients] = useState<{ name: string, description: string }[]>([]);
  const [productBIngredients, setProductBIngredients] = useState<{ name: string, description: string }[]>([]);
  const [commonIngredients, setCommonIngredients] = useState<{ name: string, description: string }[]>([]);

  const [errorMsg, setErrorMsg] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [photo, setPhoto] = useState<File | null>(null);
  const [newPhoto, setNewPhoto] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>('');
  const [comparisonBase, setComparisonBase] = useState<{ ingredients: string[], description: string, basePreviewUrl: string | null, } | undefined>(undefined);

  useEffect(() => {
    if (!newPhoto) {
      return
    }
    if (!photo) {
      return
    }
    setNewPhoto(false);
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        if (comparisonBase && previewUrl && comparisonBase.basePreviewUrl !== previewUrl) {
          setComparisonBase({
            ...comparisonBase,
            basePreviewUrl: previewUrl,
          });
        }
        setPreviewUrl(reader.result);
      }
    }
    reader.readAsDataURL(photo)
  }, [newPhoto, photo, comparisonBase, previewUrl])

  return <div className={styles['ei_container']}>
    <h1>{i18n.t('app_title', 'Explain the ingredients with AI')}</h1>
    <div className={styles['ei_content-container']}>
      {!loading ? <div className={styles['ei_upload-container']}>
        <div className={styles['ei_upload-disclaimer']}>
          <span>{i18n.t('snap_a_photo_to_explain', "Snap a picture or upload an image showing the ingredients of a product.")}</span>
        </div>
        <div className={styles['ei_upload-btn-container']}>
          <button className={styles['ei_upload-button']} onClick={() => {
            setComparisonBase(undefined);
            inputRef.current?.click();
          }}>{ingredients.length ? i18n.t("snap_a_new_photo", "Explain another one") : i18n.t("snap_a_photo_btn", "Take a photo to explain")}</button>
          {ingredients.length ? <button className={styles['ei_compare-button']} onClick={() => {
            setComparisonBase({
              description: productDescription,
              ingredients: ingredients.map(a => a.name),
              basePreviewUrl: null,
            });
            inputRef.current?.click();
          }}>{i18n.t("compare_with_btn", "Make a comparison")}</button> : null}
          <input ref={inputRef} name="photo" type="file" hidden={true} accept="image/png, image/jpeg" onChange={async (e) => {
            if (e.target.files?.length) {
              try {
                setPhoto(e.target.files[0]);
                setNewPhoto(true);
                setLoading(true);
                setProductDescription('');
                setIngredients([]);
                setProductAIngredients([]);
                setProductBIngredients([]);
                setCommonIngredients([]);
                setProductDifferences('');
                setErrorMsg('');

                if (comparisonBase) {
                  const resp = await props.apiClient.compareIngredients(e.target.files[0], comparisonBase);
                  if (resp.message) {
                    setErrorMsg(resp.message);
                    return;
                  }

                  if (resp.common_ingredients) {
                    setCommonIngredients(resp.common_ingredients);
                  }

                  if (resp.product_common) {
                    setProductDescription(resp.product_common);
                  }

                  if (resp.product_a_unique_ingredients) {
                    setProductAIngredients(resp.product_a_unique_ingredients);
                  }

                  if (resp.product_b_unique_ingredients) {
                    setProductBIngredients(resp.product_b_unique_ingredients);
                  }

                  if (resp.product_difference) {
                    setProductDifferences(resp.product_difference);
                  }

                } else {
                  const resp = await props.apiClient.explainIngredients(e.target.files[0]);

                  if (resp.message) {
                    setErrorMsg(resp.message);
                    return;
                  }

                  if (!resp.ingredients && !resp.product_description) {
                    setErrorMsg(i18n.t('general_error_message', 'Oops, something went wrong, please try again later.'));
                    return;
                  }

                  if (resp.ingredients) {
                    setIngredients(resp.ingredients);
                  }

                  if (resp.product_description) {
                    setProductDescription(resp.product_description);
                  }
                }
              } catch (err) {
                setErrorMsg(i18n.t('general_error_message', 'Oops, something went wrong, please try again later.'));
              } finally {
                setLoading(false);
              }
            }
          }} />
        </div>
        <div className={styles['ei_error']}>
          <span>{errorMsg}</span>
        </div>
      </div> : null}
      {previewUrl ? <div className={styles['ei_preview-container']}>
        {comparisonBase && comparisonBase.basePreviewUrl ? <img src={comparisonBase.basePreviewUrl} alt="Preview" className={styles['ei_preview-img']} /> : null}
        <img src={previewUrl} alt="Preview" className={styles['ei_preview-img']} /></div> : null}
      {loading ? <div className={styles['ei_loading-container']}><Loading /></div> : null}
      {productDescription ? <div>
        <h3>{i18n.t('title_product_description', 'Product description')}</h3>
        <span>{productDescription}</span>
      </div> : null}
      {productDifferences ? <div>
        <h3>{i18n.t('title_product_differences', 'Product differences')}</h3>
        <span>{productDifferences}</span>
      </div> : null}
      {productAIngredients && productAIngredients.length ? <div>
        <h3>{i18n.t('title_product_a_ingredients', 'Product A Unique Ingredients')}</h3>
        {productAIngredients.map(item => {
          return <div>
            <h4>{item.name}</h4>
            <span>{item.description}</span>
          </div>
        })}
      </div> : null}
      {productBIngredients && productBIngredients.length ? <div>
        <h3>{i18n.t('title_product_b_ingredients', 'Product B Unique Ingredients')}</h3>
        {productBIngredients.map(item => {
          return <div>
            <h4>{item.name}</h4>
            <span>{item.description}</span>
          </div>
        })}
      </div> : null}
      {commonIngredients && commonIngredients.length ? <div>
        <h3>{i18n.t('title_common_ingredients', 'Common Ingredients')}</h3>
        {commonIngredients.map(item => {
          return <div>
            <h4>{item.name}</h4>
            <span>{item.description}</span>
          </div>
        })}
      </div> : null}
      {ingredients && ingredients.length ? <div>
        <h3>{i18n.t('title_ingredients', 'Ingredients')}</h3>
        {ingredients.map(item => {
          return <div>
            <h4>{item.name}</h4>
            <span>{item.description}</span>
          </div>
        })}
      </div> : null}
    </div>
    <div className={styles['ei_footer']}></div>
  </div>
}

function Loading() {
  const i18n = useContext<I18n>(i18nContext);
  return <img className={styles['ei_loading']} src="./assets/loading.svg" alt={i18n.t('loading_alt', 'loading...')} />;
}
