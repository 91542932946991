import {createContext} from 'react';

type LoginUser = {
  credential?: string,
  user?: {
    name: string,
  };
}

export class GoogleSSO {
  private loginUser?: LoginUser;
  constructor(loginUser?: LoginUser) {
    this.loginUser = loginUser;
  }

  public isLogin() : boolean {
    return !!this.loginUser?.credential
  }
  
  public loginUserName() : string | undefined {
    return this.loginUser?.user?.name
  }
}
const googleSSOContext = createContext(new GoogleSSO());
export default googleSSOContext;